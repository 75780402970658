import React from 'react';
import PropTypes from "prop-types";
import WaveLabsButtonRadio from './button-radio';
import WaveLabsChecklist from './checklist';
import WaveLabsDatePicker from './datePicker';
import WaveLabsDropdown from './dropdown';
import WaveLabsInputTable from './input-table';
import WaveLabsShortText from './short-text';
import WaveLabsLongText from './long-text';
import WaveLabsEmail from './email';
import WaveLabsNumber from './number';
import WaveLabsLocationMap from './location-map';
import WaveLabsPhone from './phone';
import WaveLabsSectionHeader from './section-header';
import WaveLabsSignature from './signature';
import WaveLabsSingleChoice from './single-choice';
import WaveLabsButton from './button';
import WaveLabsPhoto from './photo';
import WaveLabsTiles from './tiles';
import WaveLabsTime from './Time';
import WaveLabsBarcodeScanner from './barcode-scanner';
import WaveLabsVideo from './video';
import WaveLabsAttachment from './attachment';
import useSettings from 'app/hooks/useSettings';

function V5FormElement({data, formik, index, buttonAction}) {
    const { settings } = useSettings();
    const primaryColor = settings.layout1Settings.main.primaryColor;
    const fontFamily = settings.themes.typography.fontFamily;
    return (
        <>
            {(() => {
                switch (data.element) {
                    case 'Short_Text':
                        return (
                            <WaveLabsShortText
                                customStyle={{}}
                                placeholderText={""}
                                type={"text"}
                                data={data}
                                formik={formik}
                                index={index}
                                primaryColor={primaryColor}
                                fontFamily={fontFamily}
                            />
                        );
                    case 'Long_Text':
                        return (
                            <WaveLabsLongText
                                customStyle={{}}
                                placeholderText={""}
                                type={"text"}
                                data={data}
                                formik={formik}
                                index={index}
                                primaryColor={primaryColor}
                                fontFamily={fontFamily}
                            />
                        );
                    case 'Email':
                        return (
                            <WaveLabsEmail
                                customStyle={{}}
                                placeholderText={""}
                                type={"email"}
                                data={data}
                                formik={formik}
                                index={index}
                                primaryColor={primaryColor}
                                fontFamily={fontFamily}
                            />
                        );
                    case 'Number':
                        return (
                            <WaveLabsNumber
                                customStyle={{}}
                                placeholderText={""}
                                type={"number"}
                                data={data}
                                formik={formik}
                                index={index}
                                primaryColor={primaryColor}
                                fontFamily={fontFamily}
                            />
                        );
                    case 'Dropdown':
                        return (
                            <WaveLabsDropdown 
                                customStyle={{}}
                                placeholderText={""}
                                type={"text"}
                                data={data}
                                formik={formik}
                                index={index}
                                primaryColor={primaryColor}
                                fontFamily={fontFamily}
                            />
                        );
                    case 'Single_Choice':
                        return (
                            <WaveLabsSingleChoice 
                                formik={formik} 
                                index={index} 
                                data={data}
                                primaryColor={primaryColor}
                                fontFamily={fontFamily}
                            />
                        );
                    case 'Button_Radios':
                        return (
                            <WaveLabsButtonRadio
                                formik={formik} 
                                index={index} 
                                primaryColor={primaryColor}
                                fontFamily={fontFamily}
                            data={data}/>
                        );
                    case 'Location_Coordinates':
                        return (
                            <WaveLabsLocationMap
                                customStyle={{}}
                                data={data}
                                formik={formik}
                                primaryColor={primaryColor}
                                fontFamily={fontFamily}
                                index={index} />
                        );
                    case 'Check_List':
                        return (
                            <WaveLabsChecklist 
                                customStyle={{}} 
                                data={data}
                                formik={formik} 
                                index={index}
                                primaryColor={primaryColor}
                                fontFamily={fontFamily}
                            />
                        );
                    case 'Configurable_List':
                        return (
                            <WaveLabsInputTable fontFamily={fontFamily} data={data} formik={formik} primaryColor={primaryColor} />
                        );
                    case 'Signature':
                        return (
                            <WaveLabsSignature fontFamily={fontFamily} data={data} formik={formik} primaryColor={primaryColor} />
                        );
                    case 'Section_Header':
                        return (
                            <WaveLabsSectionHeader fontFamily={fontFamily} data={data} primaryColor={primaryColor} />
                        );
                    case 'Date_Picker':
                        return (
                            <WaveLabsDatePicker fontFamily={fontFamily} data={data} formik={formik} primaryColor={primaryColor} />
                        );
                    case 'Phone':
                        return (
                            <WaveLabsPhone 
                            customStyle={{}} 
                            data={data}
                            formik={formik}
                            index={index}
                            primaryColor={primaryColor}
                            fontFamily={fontFamily}
                            />
                        );
                    case 'Button':
                        return (
                            <WaveLabsButton
                            customStyle={{}}
                            type={"button"}
                            data={data}
                            formik={formik}
                            primaryColor={primaryColor}
                            fontFamily={fontFamily}
                            index={index}
                            buttonAction={buttonAction}
                            />
                        );
                    case 'Photo':
                        return (
                            <WaveLabsPhoto data={data} fontFamily={fontFamily} primaryColor={primaryColor} formik={formik} />
                        );
                    case 'Video':
                        return (
                            <WaveLabsVideo data={data} fontFamily={fontFamily} primaryColor={primaryColor} formik={formik} />
                        );
                    case 'Barcode_Scanner':
                        return (
                            <WaveLabsBarcodeScanner fontFamily={fontFamily} primaryColor={primaryColor} data={data} formik={formik} />
                        );
                    case 'Tiles':
                        return (
                            <WaveLabsTiles data={data} fontFamily={fontFamily} formik={formik} primaryColor={primaryColor} />
                        );
                    case 'Time':
                        return (
                            <WaveLabsTime data={data} fontFamily={fontFamily} formik={formik} primaryColor={primaryColor} />
                        );
                    case 'Attachment':
                        return (
                            <WaveLabsAttachment data={data} fontFamily={fontFamily} formik={formik} primaryColor={primaryColor} />
                        )
                    default:
                        return null;
                }
            })()}
        </>

    )
}

V5FormElement.propTypes = {
    data: PropTypes.object
}

V5FormElement.defaultProps = {

};

export default V5FormElement;
